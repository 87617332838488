import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

import {
    container,
    loading,
    wrapper,
    header,
    list,
    title,
    filterHeader,
    filtersWrapper,
    switchFilter,
    listWrapper,
    count,
    loader,
    empty,
} from './shop-listing.module.scss';

import { IFilters, ISelectedFilter } from '../../models/filter.model';
import { ICity } from '../../models/prize.model';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { endpoints } from '../../config/endpoints';
import { usePrize } from '../../hooks/use-prize';
import { getUrlParamValue } from '../../utils/get-url-param-value';

import Filters from '../organisms/filters';
import ListSort from '../organisms/list-sort';
import SelectedFilters from '../organisms/selected-filters';
import AesSummary from '../molecules/aes-summary';
import AesCard from '../molecules/aes-card';
import Switch from '../atoms/switch';
import Loader from '../atoms/loader';
import Pagination from '../molecules/pagination';
import HandleFormikChange from '../hoc/handle-formik-change';

interface IShopListingProps {
    path: string;
}

const ShopListing: React.FC<IShopListingProps> = () => {
    const t = useTranslations('ShopListing');
    const { prizesData } = usePrize({ queries: ['data'] });
    const [isSent, setIsSent] = useState(false);
    const formRef = useRef<FormikProps<any>>(null);
    const deliveryParam = getUrlParamValue('delivery');
    const initialValues = getInitialValues();

    const {
        items,
        filters,
        sort,
        status,
        selectedFilters,
        pagination,
        paginationPaths,
        handleChange,
        isInitialLoading,
    } = useList<ICity>({
        endpoint: endpoints.aes.cities,
        paramsWhiteList: ['modal', 'no-scroll'],
        perPage: 12,
        additionalParams: formRef.current || deliveryParam ? {} : { delivery: 'undelivered' },
        initialSelectedFilters: initialValues,
    });

    const userFilter = filters ? filters.user : undefined;
    const deliveryFilter = filters ? filters.delivery : undefined;
    const filteredFilters = getFilteredFilters(filters);
    const filteredSelectedFilters = selectedFilters ? getSelectedFilters(selectedFilters) : [];

    useEffect(() => {
        if (status === 'success' && formRef.current) {
            setIsSent(formRef.current.values.delivery === 'delivered');
        }
    }, [status]);

    return (
        <>
            <div className={container}>
                <h1 className={header}>{t.panel}</h1>

                <Formik initialValues={initialValues} onSubmit={() => {}} innerRef={formRef}>
                    {({ resetForm, submitForm }) => (
                        <>
                            <HandleFormikChange onChange={handleChange} />
                            {!isInitialLoading && (
                                <>
                                    <AesSummary filter={userFilter} prizesData={prizesData.data} />
                                    <div
                                        className={`${wrapper} ${
                                            status === 'loading' ? loading : ''
                                        }`}
                                    >
                                        <div>
                                            <div className={filterHeader}>
                                                <p className={title}>
                                                    {t.shops} <span>{prizesData.data?.shops}</span>
                                                </p>
                                                <div className={filtersWrapper}>
                                                    <Filters
                                                        filters={filteredFilters}
                                                        handleClearFilters={() => {
                                                            resetForm();
                                                            setTimeout(() => {
                                                                submitForm();
                                                            }, 0);
                                                        }}
                                                    />
                                                </div>
                                                <Switch
                                                    className={switchFilter}
                                                    filter={deliveryFilter}
                                                />
                                            </div>
                                            <SelectedFilters
                                                selectedFilters={filteredSelectedFilters}
                                            />
                                            <div className={listWrapper}>
                                                {sort && <ListSort sortFilter={sort} />}
                                                <p className={count}>
                                                    {t.count} {pagination?.totalCount}
                                                </p>
                                            </div>
                                        </div>

                                        <div className={`${list}`}>
                                            {items &&
                                                items.length > 0 &&
                                                items.map((item) => {
                                                    return (
                                                        <AesCard
                                                            key={`card-${item.cityId}`}
                                                            card={item}
                                                            isSent={isSent}
                                                        />
                                                    );
                                                })}
                                            {items && items.length === 0 && (
                                                <p className={empty}>{t.empty}</p>
                                            )}
                                        </div>
                                        <Pagination paginationPaths={paginationPaths} />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Formik>
            </div>
            {status === 'loading' && isInitialLoading && <Loader className={loader} />}
        </>
    );
};

function getInitialValues() {
    const params = typeof window !== 'undefined' ? window.location.search.replace('?', '') : '';
    const paramsList = params.split('&');
    const newParams: { [k: string]: string } = {};

    paramsList.forEach((param) => {
        const paramList = param.split('=');

        if (paramList[0] && paramList[0] !== '') {
            newParams[paramList[0]] = paramList[1];
        }
    });

    if (Object.keys(newParams).length > 0) {
        return newParams;
    }
    return { delivery: 'undelivered' };
}

function getFilteredFilters(filters: IFilters | null | undefined) {
    const filteredFilters: IFilters = {};

    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            if (v.paramName !== 'delivery' && v.paramName !== 'user') {
                filteredFilters[k] = v;
            }
        });
    }

    return filteredFilters;
}

function getSelectedFilters(filters: ISelectedFilter[]) {
    return filters.filter((filter) => {
        return filter.paramName !== 'delivery';
    });
}

export default ShopListing;
